<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Supervisor</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span> {{ form.supervisorName }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headerReff"
              :items="form.references"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-spacer></v-spacer>
                <p class="headline font-italic text-right">References</p>
              </template>
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-data-table
              :headers="headerTask"
              :items="form.tasks"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-spacer></v-spacer>
                <p class="headline font-italic text-right">Tasks<span class="red--text">*</span></p>
              </template>
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.peopleInCharge="{ item }">
                {{ item.peopleInCharge.map(x => x.extEmployeeName).toString() }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  small
                  color="success"
                  @click.stop="complete(item)"
                  v-if="item.status === 'OUTSTANDING' && userDetail.employeeId === item.supervisor"
                >
                  <v-icon>
                    mdi-check
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-complete
      :dialog="dialog"
      :item="item"
      @complete="completed"
      @close="close"
    ></dialog-complete>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import DialogComplete from "./DialogComplete.vue";
export default {
  name: "detail-wo",
  props: {
    form: Object,
  },
  components: {
    DialogComplete,
  },
  computed: {
    ...mapState("auth", ["userDetail"]),
  },
  data: () => ({
    valid: true,
    headerReff: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Type",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "referenceId",
        sortable: false,
        divider: true,
      },
    ],
    headerTask: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Catalog",
        value: "subProcessId",
        sortable: false,
        divider: true,
      },
      {
        text: "PIC",
        value: "peopleInCharge",
        sortable: false,
        divider: true,
      },
      {
        text: "Date",
        value: "date",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
    item: {},
    taskId: null,
    dialog: false,
  }),
  methods: {
    complete(item) {
      this.item = item;
      this.taskId = item.id;
      this.dialog = true;
    },
    async completed(item) {
      item.tasks.forEach(x => {
        if (x.id === this.taskId) {
          x.status = "COMPLETED";
        }
      });

      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("wo/update", item)
        .then(() => {
          this.dialog = false;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
